import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../style-index.css";
import Image from "../../src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>First time you login</h1>
    <p>
  <span>Administrators</span> <span>Coaches</span> <span>Entrepreneurs</span>{" "}
  <span>Mentors</span>
    </p>
    <p>{`First time you register and login you need to setup your profile so you are ready to meet exciting people in the community.`}</p>
    <h2 {...{
      "id": "configure-your-profile"
    }}>{`Configure your profile`}</h2>
    <p>{`Administrator will send an invitation for you to join the program, as soon as you register you should go and complete your profile settings so that you are all set to go, it is simple.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/w_800/v1610548054/teamcoaches/documentation/Entrenador-Imagen_gxyhzk.gif" alt="Email" mdxType="Image" />
    <h2 {...{
      "id": "getting-to-your-profile"
    }}>{`Getting to your profile`}</h2>
    <p>{`Navigate to your profile by clicking in your name, a drop-down menu will show with two options `}<strong parentName="p">{`Profile`}</strong>{` and `}<strong parentName="p">{`Sign out`}</strong>{`. Click on `}<strong parentName="p">{`Profile`}</strong>{`.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/v1610511413/teamcoaches/documentation/profile_jqxnai.gif" alt="Profile" mdxType="Image" />
    <h2 {...{
      "id": "updating-your-profile"
    }}>{`Updating your profile`}</h2>
    <p>{`In your profile you can add a lot of information, from a short bio and your experience to your social network profiles.`}</p>
    <p>{`In your Profile, click on `}<strong parentName="p">{`Edit Profile`}</strong>{` then fill-in as much information as possible.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/v1610511433/teamcoaches/documentation/Edit_profile_mmkc3p.gif" alt="Edit profile" mdxType="Image" />
    <h2 {...{
      "id": "update-your-photo"
    }}>{`Update your photo`}</h2>
    <p>{`A photo will help others to identify you in meetings, so it is good to upload a good quality photo.`}</p>
    <p>{`In your Profile, click on `}<strong parentName="p">{`Edit Profile`}</strong>{` then in `}<strong parentName="p">{`Upload image`}</strong>{`.`}</p>
    <Image src="https://res.cloudinary.com/dnuwcxti0/image/upload/v1610583812/teamcoaches/documentation/Profile-picture_w8qyif.gif" alt="Picture" mdxType="Image" />
    <h2 {...{
      "id": "adding-your-skills"
    }}>{`Adding your skills`}</h2>
    <p>{`Skills are important to identify not only your area of expertise but your weak point. This helps others to identify what are the areas that needs to focus in the development of your skills.`}</p>
    <p>
  In your <span>Profile</span>, click on <span>Edit Profile</span> then in{" "}
  <span>Add Skills</span>, there is a large database of available skills but if
  you have any question please contact your Administrator or Staff member to
  find out if there is a new skill that needs to be added.
    </p>
    <h2 {...{
      "id": "your-account-and-privacy"
    }}>{`Your account and privacy`}</h2>
    <p>
  In your <span>Account</span>, you can do four different things:
    </p>
    <ul>
      <li parentName="ul">{`Synchronize your Google Account`}</li>
      <li parentName="ul">{`Change your email`}</li>
      <li parentName="ul">{`Change your timezone (Timezone is registered automatically in the browser so you can change it here if you want to have another by default)`}</li>
      <li parentName="ul">{`Change your password.`}</li>
    </ul>
    <h2 {...{
      "id": "availability"
    }}>{`Availability`}</h2>
    <p>{`Your availability is very important when you want to meet with people. For this to happen you have to configure your office hour range.`}</p>
    <p>
  In your <span>Availability</span>, you can setup multiple things which makes
  your time flexible.
    </p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Availability for booking:`}</strong>{` allows you to enable / disable your availability so for instance when you go for vacation no-one tries to book you time.`}</li>
      <li parentName="ul"><strong parentName="li">{`Max days future (days):`}</strong>{` indicates how far along you are allowing others to book meetings with you. Make sure to limit the time to 60 days if you are synchronizing with Google Calendar due to a limit put by Google on the length that can be seen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Min scheduling notice (hours):`}</strong>{` how many hours someone can see your calendar availability so you don’t get book immediately after.`}</li>
      <li parentName="ul"><strong parentName="li">{`Amount of meetings p/day:`}</strong>{` amount of meeting that you are allowing the platform to book per day.`}</li>
      <li parentName="ul"><strong parentName="li">{`Event duration:`}</strong>{` default duration of the meeting. There is an overwrite available that someone can book you more time as per request.`}</li>
      <li parentName="ul"><strong parentName="li">{`Location:`}</strong>{` where are you meeting. You can setup your default personal zoom meeting, google meet location or other place.`}</li>
      <li parentName="ul"><strong parentName="li">{`Available hours:`}</strong>{` what is the default time range allowed per day`}</li>
      <li parentName="ul"><strong parentName="li">{`Available days:`}</strong>{` what are the days available. Here you can customize days and add ranges.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      